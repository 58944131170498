import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "../service/auth/auth.service";
import { Roles } from "src/app/core/enums/roles.enum";

@Injectable({
  providedIn: "root",
})
export class TeacherGuard implements CanActivate {
  Roles;
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.id_rol;
      if (userRole !== Roles.Profesor) {
        this.router.navigate([this.router.url]);
        return false;
      }
      return true;
    }

    this.router.navigate(["/authentication/signin"]);
    return false;
  }
}
