import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "../service/auth/auth.service";
import { Roles } from "src/app/core/enums/roles.enum";

@Injectable({
  providedIn: "root",
})
export class LoginGuard implements CanActivate {
  Roles;

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.currentUserValue) {
      const userRole = this.authService.currentUserValue.id_rol;

      if (userRole === Roles.Admin) {
        this.router.navigate(["/admin/dashboard/main"]);
        return false;
      } else if (userRole === Roles.Profesor) {
        this.router.navigate(["/teacher/dashboard"]);
        return false;
      } else if (userRole === Roles.Estudiante) {
        this.router.navigate(["/student/dashboard"]);
        return false;
      } else {
        return true;
      }
    }

    return true;
  }
}
