import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  public currentTokenSubject: BehaviorSubject<any>;
  public currentToken: Observable<any>;
  public currentUserMoodleSubject: BehaviorSubject<any>;
  public currentUserMoodle: Observable<any>;
  private base = environment.apiUrl;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this.currentUser = this.currentUserSubject.asObservable();

    this.currentTokenSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("token"))
    );
    this.currentToken = this.currentTokenSubject.asObservable();

    this.currentUserMoodleSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem("currentUserMoodle"))
    );
    this.currentUserMoodle = this.currentUserMoodleSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  public get currentTokenValue(): any {
    return this.currentTokenSubject.value;
  }

  public get currentUserMoodleValue(): any {
    return this.currentUserMoodleSubject.value;
  }

  authentication(email, password): Observable<any> {
    const url = `${this.base}/usuario/login?email=${email}&password=${password}`;
    return this.http.get<any>(url);
  }

  userRegister(usuario: any): Observable<any> {
    const url = `${this.base}/usuario/registro`;
    return this.http.post<any>(url, usuario);
  }

  enviarCodigo(email: any): Observable<any> {
    const url = `${this.base}/usuario/restaurarClave`;
    return this.http.put<any>(url, email);
  }

  confirmarCodigo(email, codigo): Observable<any> {
    const url = `${this.base}/usuario/confirmarCodigo?email=${email}&codigo=${codigo}`;
    return this.http.get<any>(url);
  }

  restaurarContraseña(user): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Token " + JSON.parse(localStorage.getItem("token")),
      }),
    };

    const url = `${this.base}/usuario/actualizarClave`;
    return this.http.put<any>(url, user, httpOptions);
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
    localStorage.removeItem("token");
    localStorage.removeItem("currentUserMoodle");
    this.currentUserSubject.next(null);
    this.currentTokenSubject.next(null);
    this.currentUserMoodleSubject.next(null);
    return of({ success: false });
  }
}
