import { RouteInfo } from "./sidebar.metadata";
export const ROUTES: RouteInfo[] = [
  {
    path: "",
    title: "Menu",
    moduleName: "dashboard",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  {
    path: "/admin/dashboard/main",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/teacher/dashboard",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  {
    path: "student/dashboard",
    title: "Dashboard",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Student"],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Profesores",
  //   moduleName: "teachers",
  //   iconType: "material-icons-two-tone",
  //   icon: "person",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/teachers/all-teachers",
  //       title: "Todos los profesores",
  //       moduleName: "teachers",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/admin/teachers/all-teachers",
    title: "Profesores",
    moduleName: "teachers",
    iconType: "material-icons-two-tone",
    icon: "work",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Estudiantes",
  //   moduleName: "students",
  //   iconType: "material-icons-two-tone",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/students/all-students",
  //       title: "Todos los estudiantes",
  //       moduleName: "students",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/admin/students/all-students",
    title: "Estudiantes",
    moduleName: "students",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/teacher/students/all-students",
    title: "Estudiantes",
    moduleName: "students",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Estudiantes",
  //   moduleName: "students",
  //   iconType: "material-icons-two-tone",
  //   icon: "people_alt",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Teacher"],
  //   submenu: [
  //     {
  //       path: "/teacher/students/all-students",
  //       title: "Todos los estudiantes",
  //       moduleName: "students",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "",
  //   title: "Cursos",
  //   moduleName: "courses",
  //   iconType: "material-icons-two-tone",
  //   icon: "school",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/courses/all-courses",
  //       title: "Todos los cursos",
  //       moduleName: "courses",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/admin/courses/all-courses",
    title: "Cursos",
    moduleName: "courses",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Cursos",
  //   moduleName: "courses",
  //   iconType: "material-icons-two-tone",
  //   icon: "school",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Teacher"],
  //   submenu: [
  //     {
  //       path: "/teacher/courses/all-courses",
  //       title: "Todos los cursos",
  //       moduleName: "courses",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/teacher/courses/all-courses",
    title: "Cursos",
    moduleName: "courses",
    iconType: "material-icons-two-tone",
    icon: "school",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Teacher"],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Cursos",
  //   moduleName: "courses",
  //   iconType: "material-icons-two-tone",
  //   icon: "school",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Student"],
  //   submenu: [
  //     {
  //       path: "/student/courses/all-courses",
  //       title: "Todos los cursos",
  //       moduleName: "courses",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: "/student/courses/all-courses",
    title: "Cursos",
    moduleName: "courses",
    iconType: "material-icons-two-tone",
    icon: "assignment_turned_in",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Student"],
    submenu: [],
  },
  {
    path: "/admin/postulations/all-postulations",
    title: "Postulaciones",
    moduleName: "postulations",
    iconType: "material-icons-two-tone",
    icon: "assignment_turned_in",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/student/postulations/all-postulations",
    title: "Postulaciones",
    moduleName: "postulations",
    iconType: "material-icons-two-tone",
    icon: "assignment_turned_in",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Student"],
    submenu: [],
  },
  {
    path: "/admin/tariff/all-tariff",
    title: "Aranceles",
    moduleName: "tariff",
    iconType: "material-icons-two-tone",
    icon: "monetization_on",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/admin/tariff/all-tariff",
    title: "Reportes",
    moduleName: "tariff",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/student/tariff/all-tariff",
    title: "Aranceles",
    moduleName: "tariff",
    iconType: "material-icons-two-tone",
    icon: "monetization_on",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Student"],
    submenu: [],
  },
  {
    path: "/admin/record/all-record",
    title: "Historial",
    moduleName: "records",
    iconType: "material-icons-two-tone",
    icon: "collections_bookmark",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "/student/record/all-record",
    title: "Historial",
    moduleName: "records",
    iconType: "material-icons-two-tone",
    icon: "collections_bookmark",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Student"],
    submenu: [],
  },
  {
    path: "/student/record/all-record",
    title: "Club Confucio",
    moduleName: "records",
    iconType: "material-icons-two-tone",
    icon: "diversity_3",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Student"],
    submenu: [],
  },
  // {
  //   path: "",
  //   title: "Postulaciones",
  //   moduleName: "postulations",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment_turned_in",
  //   class: "menu-toggle",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [
  //     {
  //       path: "/admin/postulations/all-postulations",
  //       title: "Todos las postulations",
  //       moduleName: "postulations",
  //       iconType: "",
  //       icon: "",
  //       class: "ml-menu",
  //       groupTitle: false,
  //       badge: "",
  //       badgeClass: "",
  //       role: [""],
  //       submenu: [],
  //     },
  //   ],
  // },
  // {
  //   path: "/admin/calendar",
  //   title: "Calendario",
  //   moduleName: "calendar",
  //   iconType: "material-icons-two-tone",
  //   icon: "event_note",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [],
  // },
  // {
  //   path: "/admin/tickets",
  //   title: "Tickets",
  //   moduleName: "tickets",
  //   iconType: "material-icons-two-tone",
  //   icon: "fact_check",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Admin"],
  //   submenu: [],
  // },
];
